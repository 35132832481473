<template>
  <div>
    <b-row class="d-flex align-items-center mb-2">
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Mamül Kodu"
          label-for="pcode"
        >
          <b-form-input
            id="pcode"
            v-model="productFilter.keyword"
            placeholder="Mamül Kodu"
            @keydown.enter="searchData"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Ürün Grubu"
          label-for="id_product_groups"
        >
          <v-select
            id="id_product_groups"
            v-model="productFilter.id_product_groups"
            class="bg-white rounded"
            label="title"
            placeholder="Ürün Grubu"
            :reduce="item => item.id"
            :options="productGroups"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Kalite Adı"
          label-for="id_product_qualities"
        >
          <v-select
            id="id_product_qualities"
            v-model="productFilter.id_product_qualities"
            label="title"
            class="bg-white rounded"
            placeholder="Kalite Adı"
            :reduce="item => item.id"
            :options="productQualities"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Gramaj"
          label-for="weight"
        >
          <b-input-group id="weight">
            <b-form-input
              v-model="productFilter.min_weight"
              placeholder="En Düşük"
              @keydown.enter="searchData"
            />
            <b-input-group-append is-text>
              -
            </b-input-group-append>
            <b-form-input
              v-model="productFilter.max_weight"
              placeholder="En Yüksek"
              @keydown.enter="searchData"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Martindale / Cycle"
              label-for="asinma"
            >
              <b-input-group id="asinma">
                <b-form-input
                  v-model="productFilter.asinma_min"
                  placeholder="En Düşük"
                  @keydown.enter="searchData"
                />
                <b-input-group-append is-text>
                  -
                </b-input-group-append>
                <b-form-input
                  v-model="productFilter.asinma_max"
                  placeholder="En Yüksek"
                  @keydown.enter="searchData"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Kopuş"
              label-for="asinma_sonuc"
            >
              <v-select
                id="asinma_sonuc"
                v-model="productFilter.asinma_sonuc"
                :options="kopusData"
                :reduce="item => item.id"
                label="title"
                class="bg-white rounded"
                placeholder="Seçiniz"
                @input="searchData"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Pilling / Cycle"
              label-for="pilling"
            >
              <b-input-group id="pilling">
                <b-form-input
                  v-model="productFilter.pilling_min"
                  placeholder="En Düşük"
                  @keydown.enter="searchData"
                />
                <b-input-group-append is-text>
                  -
                </b-input-group-append>
                <b-form-input
                  v-model="productFilter.pilling_max"
                  placeholder="En Yüksek"
                  @keydown.enter="searchData"
                />
              </b-input-group>
            </b-form-group>

          </b-col>
          <b-col>
            <b-form-group
              label="Pilling Değeri"
              label-for="pilling_sonuc"
            >
              <b-form-input
                id="pilling_sonuc"
                v-model="productFilter.pilling_sonuc"
                placeholder="Pilling Değeri"
                @keydown.enter="searchData"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Renk Haslığı Değeri / Kuru"
          label-for="rh_kuru"
        >
          <b-form-input
            id="rh_kuru"
            v-model="productFilter.rh_kuru"
            placeholder="Renk Haslığı Değeri / Kuru"
            @keydown.enter="searchData"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Renk Haslığı Değeri / Yaş"
          label-for="rh_yas"
        >
          <b-form-input
            id="rh_yas"
            v-model="productFilter.rh_yas"
            placeholder="Renk Haslığı Değeri / Yaş"
            @keydown.enter="searchData"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Hav Kaybı Başlangıç Değeri"
          label-for="hav_kaybi"
        >
          <b-form-input
            id="hav_kaybi"
            v-model="productFilter.hav_kaybi"
            placeholder="Hav Kaybı Başlangıç Değeri"
            @keydown.enter="searchData"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-button
          style="margin-top: 4px"
          variant="primary"
          block
          @click="searchData"
        >
          <FeatherIcon icon="SearchIcon" /> Ürün Ara
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ProductSearch',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      kopusData: [
        { id: '1', title: 'Var' },
        { id: '0', title: 'Yok' },
      ],
    }
  },
  computed: {
    productFilter() {
      return this.$store.getters['catalogs/productFilter']
    },
    productGroups() {
      return this.$store.getters['productGroups/dataList']
    },
    productQualities() {
      return this.$store.getters['productQualities/dataList']
    },
  },
}
</script>
