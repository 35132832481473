<template>
  <div
    v-if="dataItem.items.length > 0"
  >
    <draggable
      v-model="items"
      tag="ul"
      class="cursor-move p-0 row match-height"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        tag="li"
        class="col-auto col-md-3"
      >
        <div
          class="border-light rounded p-1 mb-2"
          :class="item.id? 'bg-light-success' : 'bg-light'"
        >
          <div
            class="height-auto overflow-hidden"
            :class="item.id? 'bg-light-success' : 'bg-light'"
          >
            <b-img
              v-if="item.image"
              :src="baseURL + '/media/products/' + item.image"
              fluid
              class="h-auto w-100 rounded"
            />
            <empty-image v-else />
            <div class="text-center mt-1">
              <div class="font-weight-bolder text-nowrap">
                {{ item.product }}
              </div>
              <div class="font-small-2 text-muted">
                {{ item.product_quality }}
              </div>
              <div class="text-primary">
                {{ item.price | toCurrency }} {{ item.currency }}
              </div>
            </div>
          </div>
          <div class="border-top mt-1 d-flex justify-content-around">
            <b-button
              variant="outline-danger"
              size="sm"
              pill
              class="btn-icon mt-1"
              @click="removeItem(item,index)"
            >
              <FeatherIcon icon="TrashIcon" />
            </b-button>
          </div>
        </div>
      </div>
    </draggable>
    <b-alert
      show
      variant="info"
    >
      <div class="alert-body">
        <p>
          <FeatherIcon icon="InfoIcon" /> Ürünleri sıralamak için sürükleyin.
        </p>
        <p>
          <FeatherIcon icon="InfoIcon" /> Ürünleri fiyatları müşteri için hazırlanan fiyat listesinden oluşmaktadır.
        </p>
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BImg, BButton, BAlert,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import EmptyImage from '@/layouts/components/common/EmptyImage.vue'

export default {
  name: 'Products',
  components: {
    BImg,
    BButton,
    BAlert,
    draggable,
    EmptyImage,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      items: [],
      options: {
        float: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
  },
  watch: {
    dataItem: {
      deep: true,
      handler(val) {
        if (this.items.length !== val.items.length) {
          this.items = val.items
        }
      },
    },
    items(val) {
      this.dataItem.items = val
      this.$store.dispatch('catalogs/reOrderingItem', val)
    },
  },
  methods: {
    removeItem(item, index) {
      if (this.dataItem.items[index].id) {
        this.$store.dispatch('catalogs/removeLine', this.dataItem.items[index].id)
      }
      this.dataItem.items.splice(index, 1)
      const index2 = this.dataItem.id_products.findIndex(e => e === item.id_products)
      this.dataItem.id_products.splice(index2, 1)
    },
  },
}
</script>
