<template>
  <b-modal
    v-model="productFilter.modalStatus"
    size="xl"
    title="Ürün Seçimi"
    no-close-on-esc
    no-close-on-backdrop
    scrollable
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <b-button
          class="position-absolute zindex-4 position-top-0 position-right-0 mt-n1 mr-n1 btn-icon"
          variant="primary"
          pill
          @click="close"
        >
          <FeatherIcon icon="XIcon" />
        </b-button>
        <product-search
          class="mt-2"
          :search-data="searchData"
        />
      </div>
    </template>
    <div>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        small
      >
        <template #cell(pcode)="data">
          <div class="d-flex align-items-center h-100 match-height">
            <div class="width-400">
              <b-img-lazy
                v-if="data.item.image"
                :src=" baseURL + '/media/products/' + data.item.image"
                fluid
                class="rounded"
              />
              <empty-image v-else />
            </div>
            <div class="d-flex h-100 align-items-stretch">
              <div class="ml-1 d-flex flex-column">
                <div class="font-weight-bolder text-primary font-medium-2">
                  {{ data.item.pcode }}
                </div>
                <div class="font-small-3">
                  <strong>Kalite Adı:</strong> <span class="text-warning"> {{ data.item.product_quality }}</span>
                </div>
                <div class="font-small-3">
                  <strong>Ürün Grubu:</strong> <span class="text-warning"> {{ data.item.product_group }}</span>
                </div>
                <div class="font-small-3">
                  <strong>En:</strong> <span> {{ data.item.width | toNumber }}</span>
                </div>
                <div class="font-small-3">
                  <strong>Gramaj:</strong> <span> {{ data.item.weight | toNumber }}</span>
                </div>
                <div class="mt-1">
                  <strong>Liste Fiyatı:</strong> {{ data.item.price | toCurrency }} {{ data.item.currency }}
                </div>
                <div v-if="data.item.customer_price">
                  <strong>Müşteri Liste Fiyatı:</strong> {{ data.item.customer_price | toCurrency }} {{ data.item.customer_price_symbol }}
                </div>
              </div>
              <div class="ml-1 pl-1 border-left d-flex flex-column width-400 flex-fill">
                <test-result :data-item="data.item.tests" />
              </div>
            </div>
          </div>
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <b-button
              variant="primary"
              pill
              :disabled="dataItem.id_products.includes(data.item.id)"
              @click="addProduct(data.item)"
            >
              <FeatherIcon icon="CheckCircleIcon" /> Ekle
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCounts"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
        <div class="text-muted">
          Toplam {{ dataCounts }} adet ürün bulundu.
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BTable, BPagination, BImgLazy,
} from 'bootstrap-vue'
import ProductSearch from '@/views/Admin/Catalogs/elements/ProductModal/ProductSearch.vue'
import EmptyImage from '@/layouts/components/common/EmptyImage.vue'
import TestResult from '@/views/Admin/Catalogs/elements/ProductModal/TestResult.vue'

const tableName = 'products'
export default {
  name: 'ProductModal',
  components: {
    BModal,
    BButton,
    BTable,
    BPagination,
    BImgLazy,
    ProductSearch,
    EmptyImage,
    TestResult,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'pcode',
          label: 'ÜRÜN KODU',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.pcode AS pcode`,
          `${tableName}.price AS price`,
          `${tableName}.weight AS weight`,
          `${tableName}.width AS width`,
          'currencies.symbol AS currency',
          'product_groups.title AS product_group',
          'product_qualities.title AS product_quality',
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
        id_customers: null,
        showTests: true,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
    dataList() {
      return this.$store.getters['products/dataList']
    },
    dataCounts() {
      return this.$store.getters['products/dataCounts']
    },
    productFilter() {
      return this.$store.getters['catalogs/productFilter']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
    this.getProductGroups()
    this.getProductQualities()
  },
  methods: {
    addProduct(data) {
      if (this.dataItem.id) {
        this.$store.dispatch('catalogs/addLine', {
          id_products: data.id,
          ordering: this.dataItem.items.length + 1,
          id_catalogs: this.dataItem.id,
        })
          .then(response => {
            if (response.status) {
              this.$store.dispatch('catalogs/getDataItem', this.dataItem.id)
            }
          })
      } else {
        this.dataItem.items.push({
          id: null,
          product: data.pcode,
          price: data.customer_price,
          image: data.image,
          ordering: null,
          id_catalogs: null,
          id_products: data.id,
          currency: data.customer_price_symbol,
          product_quality: data.product_quality,
        })
        this.dataItem.id_products.push(data.id)
      }
    },
    getProductGroups() {
      this.$store.dispatch('productGroups/getDataList', {
        select: [
          'product_groups.id AS id',
          'product_groups.title AS title',
        ],
      })
    },
    getProductQualities() {
      this.$store.dispatch('productQualities/getDataList', {
        select: [
          'product_qualities.id AS id',
          'product_qualities.title AS title',
        ],
      })
    },
    getDataList() {
      this.dataQuery.id_customers = this.dataItem.id_customers
      this.$store.dispatch('products/getDataList', this.dataQuery)
    },
    searchData() {
      this.dataQuery.start = 0
      this.currentPage = 1
      this.dataQuery.where = {}
      if (this.productFilter.keyword) {
        this.dataQuery.like = {
          'products.pcode': this.productFilter.keyword,
        }
      } else {
        this.dataQuery.like = {}
      }
      if (this.productFilter.id_product_qualities) {
        this.dataQuery.where['products.id_product_qualities'] = this.productFilter.id_product_qualities
      }
      if (this.productFilter.id_product_groups) {
        this.dataQuery.where['products.id_product_groups'] = this.productFilter.id_product_groups
      }
      if (this.productFilter.weight) {
        this.dataQuery.where['products.weight'] = this.productFilter.weight
      }
      if (this.productFilter.min_weight) {
        this.dataQuery.where['products.weight >='] = this.productFilter.min_weight
      }
      if (this.productFilter.max_weight) {
        this.dataQuery.where['products.weight <='] = this.productFilter.max_weight
      }
      if (this.productFilter.asinma_min) {
        this.dataQuery.where['product_tests.asinma >='] = this.productFilter.asinma_min
      }
      if (this.productFilter.asinma_max) {
        this.dataQuery.where['product_tests.asinma <='] = this.productFilter.asinma_max
      }
      if (this.productFilter.asinma_sonuc) {
        this.dataQuery.where['product_tests.asinma_sonuc'] = this.productFilter.asinma_sonuc
      }
      if (this.productFilter.pilling_min) {
        this.dataQuery.where['product_tests.pilling >='] = this.productFilter.pilling_min
      }
      if (this.productFilter.pilling_max) {
        this.dataQuery.where['product_tests.pilling <='] = this.productFilter.pilling_max
      }
      if (this.productFilter.pilling_sonuc) {
        this.dataQuery.where['product_tests.pilling_sonuc'] = this.productFilter.pilling_sonuc
      }
      if (this.productFilter.rh_kuru) {
        this.dataQuery.where['product_tests.rh_kuru'] = this.productFilter.rh_kuru
      }
      if (this.productFilter.rh_yas) {
        this.dataQuery.where['product_tests.rh_yas'] = this.productFilter.rh_yas
      }
      if (this.productFilter.hav_kaybi) {
        this.dataQuery.where['product_tests.hav_kaybi'] = this.productFilter.hav_kaybi
      }
      this.getDataList()
    },
  },
}
</script>
