<template>
  <div class="w-100">
    <div class="font-weight-bolder text-primary font-medium-2">
      Test Sonuçları
    </div>
    <div class="font-small-3 border-bottom d-flex align-items-center justify-content-between flex-fill w-100">
      <strong>
        Martindale / Cycle:
      </strong>
      <div>
        <template v-if="dataItem.asinma">
          {{ dataItem.asinma | toNumber }}
        </template>
        <template v-else>
          -
        </template>
      </div>
    </div>
    <div class="font-small-3 border-bottom d-flex align-items-center justify-content-between flex-fill w-100">
      <strong>
        Kopuş:
      </strong>
      <div>
        <template v-if="dataItem.asinma_sonuc">
          <template v-if="dataItem.asinma_sonuc === '1'">
            VAR
          </template>
          <template v-else-if="dataItem.asinma_sonuc === '0'">
            YOK
          </template>
          <template v-else>
            {{ dataItem.asinma_sonuc }}
          </template>
        </template>
        <template v-else>
          -
        </template>
      </div>
    </div>
    <div class="font-small-3 border-bottom d-flex align-items-center justify-content-between flex-fill w-100">
      <strong>
        Pilling / Cycle:
      </strong>
      <div>
        <template v-if="dataItem.asinma_sonuc">
          <template v-if="dataItem.asinma_sonuc === '1'">
            VAR
          </template>
          <template v-else-if="dataItem.asinma_sonuc === '0'">
            YOK
          </template>
          <template v-else>
            {{ dataItem.asinma_sonuc }}
          </template>
        </template>
        <template v-else>
          -
        </template>
      </div>
    </div>
    <div class="font-small-3 border-bottom d-flex align-items-center justify-content-between flex-fill w-100">
      <strong>
        Pilling Değeri:
      </strong>
      <div>
        <template v-if="dataItem.pilling_sonuc">
          {{ dataItem.pilling_sonuc }}
        </template>
        <template v-else>
          -
        </template>
      </div>
    </div>
    <div class="font-small-3 border-bottom d-flex align-items-center justify-content-between flex-fill w-100">
      <strong>
        Renk Haslığı Değeri / Kuru:
      </strong>
      <div>
        <template v-if="dataItem.rh_kuru">
          {{ dataItem.rh_kuru }}
        </template>
        <template v-else>
          -
        </template>
      </div>
    </div>
    <div class="font-small-3 border-bottom d-flex align-items-center justify-content-between flex-fill w-100">
      <strong>
        Renk Haslığı Değeri / Yaş:
      </strong>
      <div>
        <template v-if="dataItem.rh_yas">
          {{ dataItem.rh_yas }}
        </template>
        <template v-else>
          -
        </template>
      </div>
    </div>
    <div class="font-small-3 d-flex align-items-center justify-content-between flex-fill w-100">
      <strong>
        Hav Kaybı Başlangıç Değeri:
      </strong>
      <div>
        <template v-if="dataItem.hav_kaybi">
          {{ dataItem.hav_kaybi }}
        </template>
        <template v-else>
          -
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TestResult',
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
  },
}
</script>
